@font-face {
    font-family: 'YuMincho-Demibold';
    src: url('../fonts/YuMincho-Demibold.eot');
    src: url('../fonts/YuMincho-Demibold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/YuMincho-Demibold.woff2') format('woff2'),
        url('../fonts/YuMincho-Demibold.woff') format('woff'),
        url('../fonts/YuMincho-Demibold.ttf') format('truetype'),
        url('../fonts/YuMincho-Demibold.svg#YuMincho-Demibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'YuMincho-Regular';
    src: url('../fonts/YuMincho-Regular.eot');
    src: url('../fonts/YuMincho-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/YuMincho-Regular.woff2') format('woff2'),
        url('../fonts/YuMincho-Regular.woff') format('woff'),
        url('../fonts/YuMincho-Regular.ttf') format('truetype'),
        url('../fonts/YuMincho-Regular.svg#YuMincho-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}






